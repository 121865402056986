import React, {useContext, useEffect, useState} from 'react';
import './Map.css';
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import {SearchContext} from "../contexts/search.context";
import {SimpleAdEntity} from 'types';
import {SingleAd} from "./SingleAd";
import { apiUrl } from '../../config/api';
import 'leaflet/dist/leaflet.css';
import '../../utils/fix-map-icon';

export const Map = () => {
      const {search} = useContext(SearchContext);
        const [ads, setAds] = useState<SimpleAdEntity[]>([]);
      // useEffect(() => {
      //     console.log('Make request to search for', search)
      // }, [search]);

    useEffect(() => {
        (async () => {
          const res = await fetch(`${apiUrl}/ad/search/${search}`);
          const data = await res.json();

          setAds(data);
        })()
    }, [search]);

    return (
        <div className="map">
            <MapContainer center={[52.2190575,20.3233591]} zoom={13}>
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution="&copy; <a href='https://www.openstreetmap.org/copyright'>OpenStreetMap</a> & contributors"
                />
                {
                    ads.map(ad => (
                        <Marker key={ad.id} position={[ad.lat, ad.lon]}>
                            <Popup>
                                <SingleAd id={ad.id}/>
                            </Popup>
                        </Marker>
                    ))
                }
            </MapContainer>
        </div>
    )
};